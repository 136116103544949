import logo from './images/acumenLogo6.png';
import './App.css';

function App() {
  return (
    <div className="App">
      <center>
        <img className="logo" src={logo} alt='logo' />
        <div className='text'>
          Insight-driven consulting coming your way soon.
        </div>
        <div className="jumping-dots">
  <div className="dot"></div>
  <div className="dot"></div>
  <div className="dot"></div>
</div>
      </center>

    </div>
  );
}

export default App;
